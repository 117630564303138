<template>
  <i-container v-if="response" class="_margin-bottom-6 bock--container">
    <i-row>
      <i-column
        xs="12"
        sm="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(item, index) in response.data.body"
        :key="`piece-${index}`"
        class="tile"
      >
        <div
          @click="
            $router.push({
              name: 'Exhibit',
              params: { uid: response.uid, id: slugify(item.primary.title1) },
            })
          "
          class="bock--hover"
        >
          <prismic-image
            v-if="item.primary.image.square"
            :field="item.primary.image.square"
            class="image -responsive"
          />
          <div class="_padding-top-0 _margin-bottom-1">
            <small class="_text-muted _font-weight-semibold bock--trunc">
              {{ item.primary.title1 }}
            </small>
          </div>
        </div>
      </i-column>
    </i-row>
    <!-- <pre v-if="prev" v-html="JSON.stringify(prev, null, 2)"></pre> -->
  </i-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Exhibition",
  data() {
    return {
      response: null,
      exhibitions: null,
      info: null,
      prevIndex: null,
      prev: null,
      next: null,
      nextIndex: null,
    };
  },
  computed: {
    ...mapGetters(["activeExhibit", "exhibits"]),
  },
  methods: {
    ...mapActions(["setExhibition", "setExhibits"]),
    async getContent() {
      const response = await this.$prismic.client.getByUID(
        "exhibition",
        this.$route.params.uid
      );
      this.response = response;
      this.getInfo();
    },
    async getOthers() {
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "exhibition"),
        {
          orderings: "[my.exhibition.sort_date desc]",
          pageSize: 100,
        }
      );
      this.$store.dispatch("setExhibits", response.results);
      this.getButts();
    },
    getButts() {
      const index = this.$store.state.exhibits.findIndex(
        (exhibits) => exhibits.uid === this.$route.params.uid
      );
      if (index == 0) {
        this.prevIndex = this.$store.state.exhibits.length - 1;
        this.nextIndex = index + 1;
      } else if (index == this.$store.state.exhibits.length - 1) {
        this.prevIndex = index - 1;
        this.nextIndex = 0;
      } else {
        this.prevIndex = index - 1;
        this.nextIndex = index + 1;
      }
    },
    getInfo() {
      if (
        this.$store.state.activeExhibit.title === null ||
        this.$store.state.activeExhibit.title !== this.response.data.title
      ) {
        this.exhibit = {
          title: this.response.data.title,
          year: this.response.data.year,
          gallery: this.response.data.gallery,
          location: this.response.data.location,
        };
        this.$store.dispatch("setActiveExhibit", this.exhibit);
        this.getOthers();
      }
      this.info = {
        thumb: this.response.data.install_shot.square,
        info: this.response.data.info,
        truncate: this.truncate(this.response.data.info),
      };
      this.$store.dispatch("setExhibition", this.info);
    },
    deleteInfo() {
      this.info = {
        thumb: null,
        info: [],
        truncate: null,
      };
      this.$store.dispatch("setExhibition", this.info);
    },
    truncate(string) {
      let values = [];
      if (string.length) {
        string.forEach((str) => {
          values = values.concat(str.text.split(" "));
        });
      }
      // values.flat(2)
      // console.log(values)
      // let value = string[0].text.split(" ");
      if (values.length > 82 || string.length > 1) {
        values = values.slice(0, 82).join(" ") + "...";
      } else {
        if (string.length) {
          values = string[0].text;
        }
      }
      return values;
    },
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");
      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
  },
  watch: {
    prevIndex(val) {
      this.prev = this.$store.state.exhibits[val];
    },
    nextIndex(val) {
      this.next = this.$store.state.exhibits[val];
    },
  },
  created() {
    this.getContent();
  },
  beforeDestroy() {
    this.deleteInfo();
  },
};
</script>
